.aboutbg1{
    /* background-image: url('../img/back3.jpg'); */
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Parallax Effect for DIV 3 */
    /* min-height:700px; */
}
.beans{
    background-position: 'fixed';
}

/* .left-tt{
    display: flex;
    gap: 2rem;
    flex: 1 1;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
} */

.left-t>:nth-child(4)
{
text-transform: none;
text-align: justify;
letter-spacing: 2px;
line-height: 40px;
}
.right-tt{
    flex: 1;
    position: relative;
     
}
/* .imgg{
    width: 17rem;
    height: 20rem;
    position: absolute;
   
    right: 307px;
    top:5rem;   
} */


/* .arrow{
    display: flex;
    gap:1rem;
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    
} */
.arrow>img{
    width: 19%;
    cursor: pointer;
}
.bggg{
    background-image: url('../img/redbg.jpg');
    background-repeat: no-repeat;
    border-bottom-left-radius: 188px;
    /* border-top-left-radius: 61px; */


  
}
.imggg{
    width: 30%;
    margin-left: 132px;
    padding-top: 3%;
    padding-bottom: 3%;
}

/* .bgarrow{
  background-image: url('../img/bgwhite.png');
    background-position: 502px 50px; 

    background-attachment: fixed;
} */


@media only screen and (max-width: 1000px) {
  
    .aboutflex{
        flex-wrap: wrap;
    }
    .left-hh{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
       
    .imggg {
        width: 24%;
    /* width: 517px; */
    height: 100%;
    margin-left: -4px;
    }
    .right-hh{
        flex-direction: row-reverse;
        justify-content: center;
        margin-top: 36px;
    }
    .arrow{
        display: none !important;
    }
    .mediaQ {
        display: flex !important;
        justify-content: center;
    /* margin-top: 48px; */
    gap: 2rem;
    width: 67%;
    }
    
    .mediaQ>img{
        height: 26vh;
    }
  
    .bggg{
        border-bottom-left-radius: 71px;
    }
    .image-coff{
        display: none;
    }
    .right-hh {
        width: 100%;
    }
    .flavour-heading{
        text-align: center;
        width: 35%;

    display: flex !important
    }
    }

    

    .mediaQ{
        display: none;

    }
    @media only screen and (max-width: 600px) {
        .mediaQ>img{
            height: 16vh;
        }
   
    }
    /* .bgarrow>img{
        width: 25%;

    }
     */
 
     .flavour-heading{
     display: none;

     }