/* .container{
    display: flex;
    justify-content: center;
    align-items: center;
} */
@import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Damion&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yesteryear&display=swap');
.fontss{
   
    font-family: 'Bebas Neue', 'cursive';
}
.background-img1{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 750px !important;
      opacity: 0.9;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: auto;
      height: auto;
      z-index: -3;
      background-color: #000000;
      
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }
 .container2{
    display: flex;
    /* justify-content: center; */
    justify-content: space-around;
    /* margin-top: 60px; */
   

 }
.left-h{
    /* background-image: url('../img/left.png');
    background-repeat:no-repeat ;
    background-position: 50px -200px; 
    */
}
.right-h{
    /* background-image: url('../img/right.png');
    background-repeat: no-repeat;
    background-position: 50px -200px; */
}
.right-h{
    margin-top: 20px;
}
.back22{
    background-image: url('../img/left.png');
    background-position: -44px 2px; 
    background-repeat: no-repeat;
  
}
.back11{
    background-image: url('../img/right.png');
    background-position: 961px 11px;
    background-repeat: no-repeat;
}
.container3{
    display: flex;
    justify-content: space-around;
    /* text-align: center; */
    margin-top: 55px;
    align-items: center;
    align-content: center;
    
    padding-left: 97px;

 }
 .bg5{
    background-image: url('../img/back2.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px !important;
    background-attachment: fixed; /* Parallax Effect for DIV 3 */
    min-height: 600px;
 }
 .container5{
    display: flex;
    margin-top: 135px;
    justify-content: center;
    align-items: center;
    align-content: center;

 }
 .container5{
    background-image: url('../img/corn.png');
    background-repeat: no-repeat;
    background-position: 965px 4px;
 }
 .container6{
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .cont{
    margin-top: 25px;
 }
.paragraph{
    margin-left: 64px;
    margin-top: -166px;
    font-size: 20px;
    text-align: center;
}
.cont{
    background-image: url('../img/right.png');
    background-repeat: no-repeat;
    background-position: 371px 7px;
}
.container6{
    background-image: url('../img/stamp.png');
    background-repeat: no-repeat;
    background-position: 134px 156px;
}
.container7{
    background-image: url('../img/back3.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px !important;
    background-attachment: fixed; /* Parallax Effect for DIV 3 */
    min-height: 600px;
}
.fonttt{
    color: #C7A17A;
    font-family: "arkipelagoregular", Sans-serif;
    font-size: 60px;
    text-transform: none;
}
.container88{

}
.vertical {
    border-left: 1px solid #C7A17A;
    height: 200px;
    position:absolute;
    left: 50%;
}
.container88 {
    display: flex;
    justify-content: center;
    align-content: space-around;
    flex-direction: column;
}
.font2{
    font-family: 'Yesteryear', 'cursive';
    font-weight: 300;
}
.font3{
    font-family: 'Yesteryear', 'cursive';
    font-weight: 300;
    color: #C7A17A;
    text-transform: capitalize;
}
/* @media only screen and (min-width: 1000px) {
    .container2{
        flex-wrap: wrap;
    }
    .bgg{
        margin-top: 51px;
    }
}
@media only screen and (min-width: 400px) {
    .container2{
        flex-wrap: wrap;
    }
    .bgg{
        margin-top: 62px;
    }
}
 */

.bgg{

}
.font5{
        margin-top: -32px
}
.background-img1{
    background-attachment: fixed; /* Parallax Effect for DIV 3 */
    min-height: 600px;
}


@media only screen and (max-width: 800px) {

    .background-img1 {
        background-attachment: scroll;
        height: 542px !important;
       
    }
    
    .container7{
 
        background-attachment: scroll !important; 
    }

    .bgg {
padding-top: 11px !important;
    width: 100% !important;
    }

    .container2 {
        display:none ;
      
    }
   .coffeepic-1 {
    width: 50%;
    margin-left: 10px;
   }
   .container11{
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-top: 125px;
   }

   .container11>img{
  width: 50%;

   }
   .ourr{
    font-size: xxx-large;
  
   }
   .para{
    font-family: 'Merienda', cursive;
   }
   .container3 {
    display: flex;
    justify-content: center;
    margin-top: -187px;
    align-items: center;
    align-content: center;
    padding-left: 97px;
    flex-direction: column-reverse;
    flex-wrap: wrap;
}
.im{
    margin-left: 0px !important;
    width: 80% !important;
}
.para11{
    font-size: small;
}
.coffeepic-0{
    /* width: 77% !important; */
}
.container3{
display: none;
}
.container33{
    display: flex !important;
    display: flex !important;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: -189px;
}
.bg5 {
   
    background-repeat: no-repeat;
    background-size: cover;
    height: 260px !important;
    background-attachment:scroll !important;
    min-height: 221px;
}
.craft{
    font-size: 42px !important; 
    letter-spacing: 10px !important;
    margin-top: -70px !important;
}
.left-rr{
    width: 80% !important;
}
.container55{
    display: flex !important;
    margin-top: 48px;
    flex-wrap: wrap;
    justify-content:center;
   
}
.container5{
    display: none !important;
}
.cont{
    display: none !important;
}
.cont2{
    display: flex !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;

}
.nhh{
    width: 100%;
}
.container77{
    display: flex !important;  
}
.container7{
    display: none !important;
}
.container77{

}
.craftt{
    margin-top: 92px;
}
.container66{
   
    background-attachment:scroll !important; 
 
 }
 .container77{
   
    background-attachment:scroll !important; 
 
 }

}



.container11{
    display: none;
}
.container33{
    display: none;
}
.container55{
    display: none;

}
.cont2{
    display: none;
}
.bgg1{
    display: none;
}
.container77{
    background-image: url('../img/back3.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px !important;
    background-attachment: fixed; 
    min-height: 600px;
}
.container66{
    background-image: url('../img/back2.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px !important;
    background-attachment: fixed; 
 
 }