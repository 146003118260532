body {
  padding: 0;
  margin: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Merienda+One&display=swap');


.parallax-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: Montserrat, sans-serif;
  width: 100%;
  min-height: 100vh;
  flex-wrap: wrap;
}

/* .parallax-item h2 {
  font-size: 3rem;
  text-transform: uppercase;
  background-color: whitesmoke;
  padding: 1rem;
  border-radius: 1rem;
} */

.parallax-item:first-child {
  background: url('../img/background1.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.parallax-item:nth-child(2) {
  background: #fdd835;
  background-attachment: fixed;
}

.parallax-item:nth-child(3) {
  background: url("../img/background3.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Parallax Effect for DIV 3 */
  min-height: 600px;
  
}

.parallax-item:nth-child(4) {
  background: #1565c0;
}

@media screen and (max-width: 768px) {
  .parallax-item h2 {
    /* font-size: 3rem; */
  }
  .roww{
    flex-wrap: wrap;
    margin-top: 30px
  }
  .roww2{
    flex-wrap: wrap;
    margin-top: 30px
  }
  .flex1{
    width:471px ;
  }
  .flex2{
    width:471px;
  }
  .flex3{
    width:471px;
  }
  .flex4{
    width:471px ;
  }
}

@media screen and (max-width: 600px) {

  .flex1{
    width:471px ;
  }
  .flex2{
    width:471px;
  }
  .flex3{
    width:471px;
  }
  .flex4{
    width:471px ;
  }
}

.text1{

  text-transform: capitalize;
  font-family: 'Merienda One', cursive;
  font-size: 50px;
}
.flex1{
  border-bottom-right-radius: 188px;
    border-top-left-radius: 61px;

}
.flex2{
  border-bottom-right-radius: 188px;
    border-top-left-radius: 61px;
    background-attachment: fixed;
   
}
.flex3{
  border-bottom-right-radius: 188px;
    border-top-left-radius: 61px;

}
.flex4{
  border-bottom-right-radius: 188px;
    border-top-left-radius: 61px;
    background-attachment: fixed;
   
}

@media screen and (max-width: 1120px) {
  .roww{
    flex-direction: column;
    gap: 24rem !important;
  }   
  .roww2{
    flex-direction: column;
    margin: 68px;
  }
  
}

@media screen and (max-width: 500px) {

  .flex1{
    width:410px !important ;
  }
  .flex2{
    width:410px !important;
  }
  .flex3{
    width:410px !important;
  }
  .flex4{
    width:410px !important ;
  }
}
.roww{
  gap: 28rem;
}
.text2{
  text-align: center;
}